/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import {
	AfterViewInit,
	Directive,
	EventEmitter,
	Input,
	Output,
	ViewChild,
} from '@angular/core';
import {
	CdkDragDrop,
	CdkDragEnter,
	CdkDropList,
	CdkDropListGroup,
} from '@angular/cdk/drag-drop';
import { MoveModel } from '@shared/models';

@Directive()
export class BaseOrderedGridComponent implements AfterViewInit {
	@ViewChild(CdkDropListGroup) listGroup: CdkDropListGroup<CdkDropList>;
	@ViewChild('placeholder', { read: CdkDropList }) placeholder: CdkDropList;

	source: CdkDropList;
	sourceElement: HTMLElement;

	get placeholderElement() {
		return this.placeholder.element.nativeElement;
	}

	@Output() moveItem = new EventEmitter<MoveModel>();
	get canDrag() {
		return this.moveItem.observed && !this.disableDrag;
	}

	@Input() disableDrag = false;

	ngAfterViewInit() {
		this.placeholderElement.style.display = 'none';
	}

	resetDrag() {
		this.source = null;
		this.placeholder.data = null;
		this.placeholderElement.parentNode.insertBefore(
			this.sourceElement,
			this.placeholderElement
		);
		this.sourceElement = null;
		this.placeholderElement.style.display = 'none';
		this.placeholderElement.parentNode.prepend(this.placeholderElement);
	}

	drop(event: CdkDragDrop<number>) {
		if (!this.canDrag) {
			return;
		}

		let newIndex = event.container.data;
		if (!newIndex) {
			return;
		}

		this.resetDrag();

		const oldIndex = event.previousContainer.data;

		newIndex = Math.ceil(newIndex);

		if (newIndex === oldIndex) {
			return;
		}

		this.moveItem.emit({ id: event.item.data, sortingKey: newIndex });
	}

	onEnter(event: CdkDragEnter<number>) {
		// If drag started remove source list (Replaced with placeholder)
		if (!this.source) {
			this.source = event.item.dropContainer;
			this.sourceElement = this.source.element.nativeElement;
			this.sourceElement.parentNode.removeChild(this.sourceElement);
		}

		// Calculate index change
		const oldIndex = this.placeholder.data ?? event.item.dropContainer.data;
		let newIndex = event.container.data;
		const increased = newIndex > oldIndex;

		// Modify new index to be placed between existing integers
		if (increased) {
			newIndex += 0.5;
		} else {
			newIndex -= 0.5;
		}

		// Store new Index in placeholder list
		this.placeholder.data = newIndex;

		// Un-hide the placeholder
		this.placeholderElement.style.display = '';

		// Move the placeholder to the new position
		const dropElement = event.container.element.nativeElement;
		dropElement.parentNode.insertBefore(
			this.placeholderElement,
			increased ? dropElement.nextSibling : dropElement
		);

		// Place the temp item inside the placeholder list (To make sure placeholder has the correct size)
		this.placeholder._dropListRef.enter(
			event.item._dragRef,
			event.item.element.nativeElement.offsetLeft,
			event.item.element.nativeElement.offsetTop
		);
	}

	getId(_index: number, item: { id: string }) {
		return item.id;
	}
}
