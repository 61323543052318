// action models

export interface CreateAdminModel {
	username: string;
	email?: string;
	forceNewPassword?: boolean;
	password: string;
	firstName?: string;
	lastName?: string;
	role: string;
	sendConfirmationEmail: boolean;
	twoFactorEnabled?: boolean;
}

export interface UpdateAdminModel {
	id: string;
	username?: string;
	email?: string;
	twoFactorEnabled?: boolean;
	firstName?: string;
	lastName?: string;
	phoneNumber?: string;
	role?: string;
	disabled?: boolean;
}
