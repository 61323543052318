import { Injectable } from '@angular/core';
import { Sorted } from '@lib/redux';
import {
	EntityComponentStoreConfig,
	EntityState,
	ExtractEntity,
	SortComparer,
	Update,
} from '@rx-mind/entity-component-store';
import { clamp } from 'lodash-es';
import { filter, map, pipe, tap, withLatestFrom } from 'rxjs';
import { CoEntityComponentStore } from '../co-entity-component-store/co-entity-component-store';

/**
 * Options for {@link CoSortedEntityComponentStore.moveOne}.
 */
export interface MoveOneOptions<TId extends string = string> {
	/**
	 * The `id` property of the entity to move.
	 */
	readonly id: TId;
	/**
	 * The sorting key to move the entity to.
	 */
	readonly sortingKey: number;
}

/**
 * Options for {@link CoSortedEntityComponentStore.relocateOne}.
 */
export interface RelocateOneOptions<
	TEntity extends {
		readonly id: TId;
	} & Sorted,
	TId extends string = string,
	TParentId extends string = string
> {
	/**
	 * The `id` property of the entity to relocate.
	 */
	readonly id: TId;
	/**
	 * The ID of the parent that is the sorting context to relocate the entity
	 * to.
	 */
	readonly newParentId: TParentId;
	/**
	 * The entity key (property name) identifying the ID of the parent entity
	 * that is the sorting context.
	 */
	readonly parentIdKey: keyof TEntity;
	/**
	 * The sorting key to move the entity to in the specified sorting context.
	 */
	readonly sortingKey: number;
}

/**
 * Component store with sorted entity updaters and selectors.
 * @see {@link EntityComponentStore}
 *
 * @remarks {@link all$} fixes a timing issue in
 *   {@link EntityComponentStore.all$}.
 * @remarks Sorts entities by `sortingKey` in ascending order.
 * @remarks Bulk entity methods accepts read-only arrays to enforce immutable
 *   shared state.
 *
 * @example
 * interface ProductsState extends EntityState<Product, number> {
 *   query: string;
 * }
 *
 * const initialState = getInitialEntityState<ProductsState>({ query: '' });
 *
 * \@Injectable()
 * export class ProductsStore extends EntityComponentStore<ProductsState> {
 *   private readonly query$ = this.select(s => s.query);
 *
 *   readonly vm$ = this.select(
 *     this.all$,
 *     this.query$,
 *     (allProducts, query) => ({
 *       products: allProducts.filter(p => p.name.includes(query)),
 *       query,
 *     }),
 *   );
 *
 *   constructor(private readonly productsService: ProductsService) {
 *     super({ initialState });
 *   }
 *
 *   readonly loadProducts = this.effect<void>($ => {
 *     return $.pipe(
 *       concatMap(() =>
 *         this.productsService.getAll().pipe(
 *           tapResponse(
 *             products => this.setAll(products);
 *             console.error,
 *           ),
 *         )
 *       ),
 *     );
 *   });
 *
 *   readonly deleteProduct = this.effect<number>(id$ => {
 *     return id$.pipe(
 *       concatMap(id =>
 *         this.productsService.create(id).pipe(
 *           tapResponse(
 *             () => this.removeOne(id),
 *             console.error,
 *           ),
 *         ),
 *       ),
 *     ),
 *   });
 * }
 */
@Injectable()
export abstract class CoSortedEntityComponentStore<
	TState extends EntityState<TEntity, TId>,
	TEntity extends {
		readonly id: TId;
	} & Sorted = ExtractEntity<TState>,
	TId extends string = string
> extends CoEntityComponentStore<TState, TEntity, TId> {
	constructor(config: EntityComponentStoreConfig<TState, TEntity, TId> = {}) {
		super({
			...config,
			sortComparer: config.sortComparer ?? sortedComparer,
		});
	}

	/**
	 * Change the sorting key of an entity.
	 *
	 * Update the sorting key of the affected sorted entities.
	 */
	protected moveOne = this.effect<MoveOneOptions<TId>>(
		pipe(
			withLatestFrom(this.all$),
			map(([options, entities]) => ({ ...options, entities })),
			tap(({ id, entities, sortingKey: toSortingKey }) => {
				if (entities.length === 0) {
					return;
				}

				const entity = entities.find(x => x.id === id);

				if (!entity) {
					return;
				}

				const allSortingKeys = entities.map(x => x.sortingKey);
				const minSortingKey = clamp(
					Math.min(...allSortingKeys),
					0,
					Number.MAX_SAFE_INTEGER
				);
				const maxSortingKey = clamp(
					Math.max(...allSortingKeys),
					0,
					Number.MAX_SAFE_INTEGER
				);
				const fromSortingKey = clamp(
					entity.sortingKey,
					minSortingKey,
					maxSortingKey
				);
				toSortingKey = clamp(toSortingKey, minSortingKey, maxSortingKey);

				if (fromSortingKey === toSortingKey) {
					return;
				}

				const lowerBound = Math.min(fromSortingKey, toSortingKey);
				const upperBound = Math.max(fromSortingKey, toSortingKey);
				const delta = fromSortingKey < toSortingKey ? -1 : 1;
				const changeSet = entities
					.filter(x => lowerBound <= x.sortingKey && x.sortingKey <= upperBound)
					.map(
						(x): Update<TEntity, TId> => ({
							id: x.id,
							changes: {
								sortingKey: x.id === id ? toSortingKey : x.sortingKey + delta,
							} as Partial<TEntity>,
						})
					);

				this.updateMany(changeSet);
			})
		)
	);

	protected relocateOne = this.effect<RelocateOneOptions<TEntity, TId>>(
		pipe(
			map(options => ({
				options,
				relocatingEntity: this.getOneOrThrow(options.id),
			})),
			map(({ options, relocatingEntity }) => ({
				oldSortingKey: relocatingEntity.sortingKey,
				oldSortingParentId: relocatingEntity[options.parentIdKey] as string,
				options,
			})),
			filter(
				({ oldSortingParentId, options }) =>
					oldSortingParentId !== options.newParentId
			),
			withLatestFrom(this.all$),
			map(([{ oldSortingKey, oldSortingParentId, options }, xs]) => ({
				options,
				oldSortingGroup: xs.filter(
					x =>
						x.id !== options.id && x[options.parentIdKey] === oldSortingParentId
				),
				newSortingGroup: xs.filter(
					x => x[options.parentIdKey] === options.newParentId
				),
				oldSortingKey,
			})),
			tap(({ newSortingGroup, oldSortingGroup, oldSortingKey, options }) => {
				const newSortingGroupChangeSet = this.#insertGapInSortingGroup({
					entities: newSortingGroup,
					sortingKey: options.sortingKey,
				});
				const relocatingEntityChange: Update<TEntity, TId> = {
					id: options.id,
					changes: {
						[options.parentIdKey]: options.newParentId,
						sortingKey: options.sortingKey,
					} as Partial<TEntity>,
				};
				const oldSortingGroupChangeSet = this.#removeGapFromSortingGroup({
					entities: oldSortingGroup,
					sortingKey: oldSortingKey,
				});

				this.updateMany([
					...newSortingGroupChangeSet,
					relocatingEntityChange,
					...oldSortingGroupChangeSet,
				]);
			})
		)
	);

	/**
	 * Insert a gap at the specified sorting key in the specified sorting group to
	 * make room for relocating an entity.
	 */
	#insertGapInSortingGroup({
		entities,
		sortingKey,
	}: {
		readonly entities: readonly TEntity[];
		readonly sortingKey: number;
	}): readonly Update<TEntity, TId>[] {
		return entities
			.filter(x => x.sortingKey >= sortingKey)
			.map(x => ({
				id: x.id,
				changes: {
					sortingKey: x.sortingKey + 1,
				} as Partial<TEntity>,
			}));
	}

	/**
	 * Remove the gap at the specified sorting key after removing an entity from
	 * the specified sorting group.
	 */
	#removeGapFromSortingGroup({
		entities,
		sortingKey,
	}: {
		readonly entities: readonly TEntity[];
		readonly sortingKey: number;
	}): readonly Update<TEntity, TId>[] {
		return entities
			.filter(x => x.sortingKey > sortingKey)
			.map(x => ({
				id: x.id,
				changes: {
					sortingKey: x.sortingKey - 1,
				} as Partial<TEntity>,
			}));
	}
}

const sortedComparer: SortComparer<Sorted> = (a, b) => {
	return a.sortingKey - b.sortingKey;
};
