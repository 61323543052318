import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserSelfie } from '@consensus/connect/shared/selfie/domain';
import { environmentToken } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class SelfieClient {
	readonly #apiServer = inject(environmentToken).server;
	readonly #mediaServer = inject(environmentToken).mediaServer;
	readonly #httpClient = inject(HttpClient);

	createUserSelfie(selfieId: string, isPortrait?: boolean) {
		return this.#httpClient.post<UserSelfie>(`${this.#apiServer}/api/selfie`, {
			selfieId,
			isPortrait,
		});
	}

	getUserSelfies(selfieId: string) {
		return this.#httpClient.get<UserSelfie[]>(
			`${this.#apiServer}/api/selfie/${selfieId}`
		);
	}

	downloadSelfies(selfieIds: string[], selfieId: string) {
		return this.#httpClient.post(
			`${this.#mediaServer}/selfies/${selfieId}/download`,
			selfieIds,
			{
				responseType: 'arraybuffer',
				headers: {
					accept: ['application/octet-stream'],
				},
			}
		);
	}

	getUserSelfie(selfieId: string) {
		return this.#httpClient.get<UserSelfie>(`${this.#apiServer}/api/selfie/`, {
			params: { selfieId },
		});
	}

	selfieUploaded(selfieId: string, userSelfieId: string) {
		return this.#httpClient.patch(
			`${this.#apiServer}/api/selfie/${selfieId}/user/${userSelfieId}/uploaded`,
			{}
		);
	}

	createAdminSelfie(selfieId: string) {
		return this.#httpClient.post<UserSelfie>(
			`${this.#apiServer}/api/selfie/${selfieId}/admin`,
			{}
		);
	}

	toggleOverlay(selfieId: string, show: boolean) {
		return this.#httpClient.post(
			`${this.#apiServer}/api/selfie/${selfieId}/overlay`,
			{ show }
		);
	}
}
