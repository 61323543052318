// ColearnUserViewModel

export interface ColearnUserViewModel {
	competitionName: string;
	isManager: boolean;
	teamId: string;
	teamName: string;
	userId: string;
	userName: string;
	subjects: ColearnUserViewModel[];
}
