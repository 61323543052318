/**
 * Takes a targetList of objects and partially update this list based on the
 * changeList. The identifierProperty is used to uniquely identify each object.
 * @param targetList
 * @param changeList
 * @param identifierProperty
 */
export function updateObjects<T>(
	targetList: T[],
	changeList: Partial<T>[],
	identifierProperty: keyof T
): T[] {
	const updatedList = [];

	for (const changeObject of changeList) {
		const identifierValue = changeObject[identifierProperty];
		if (identifierValue) {
			const targetObject = targetList.find(
				x => x[identifierProperty] === identifierValue
			);

			if (targetObject) {
				updatedList.push(Object.assign({ ...targetObject }, changeObject));
			}
		}
	}

	return updatedList;
}
