/**
 * Updates sorting keys for all items in a list of items based on
 * the `newSortingKey` for the item selected by the `selector` and optionally
 * by the `identifier`.
 * @param originalSortableList The list to update sorting keys in
 * @param selector The selector predicate to for the item to be moved
 * @param newSortingKey The new sorting key for the item to be moved
 * @param identifier Optional identifier if the items to be updated is
 * only a subset of the list.
 *
 * @return A new list with the updated sorting keys
 */
export function moveToSortingKeyAndReorderList<
	T extends { sortingKey: number }
>(
	originalSortableList: T[],
	newSortingKey: number,
	selector: (x: T) => boolean,
	identifier: ((x: T) => string) | null = null
) {
	const sortableList = originalSortableList.map(f => ({ ...f }));
	const sortableItem = sortableList.find(selector);

	if (!sortableItem) {
		return sortableList;
	}

	if (newSortingKey < 0) {
		return sortableList;
	}

	const noSortingToBeDone = sortableItem.sortingKey == newSortingKey;
	if (noSortingToBeDone) {
		return sortableList;
	}

	const max = Math.max(0, ...sortableList.map(x => x.sortingKey));
	if (newSortingKey > max) {
		newSortingKey = max;
	}

	const oldSortingKey = sortableItem.sortingKey;
	const identity = identifier ? identifier(sortableItem) : null;

	if (oldSortingKey < newSortingKey) {
		sortableList
			.filter(
				s =>
					s.sortingKey > oldSortingKey &&
					s.sortingKey <= newSortingKey &&
					(!identifier || identifier(s) === identity)
			)
			.forEach(s => s.sortingKey--);
	} else {
		sortableList
			.filter(
				s =>
					s.sortingKey < oldSortingKey &&
					s.sortingKey >= newSortingKey &&
					(!identifier || identifier(s) === identity)
			)
			.forEach(s => s.sortingKey++);
	}

	sortableItem.sortingKey = newSortingKey;

	return sortableList;
}
