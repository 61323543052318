import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
	LearningPackageEntityType,
	LearningPackagePreviewLinkResponse,
} from '@consensus/shared/shared/learning-package/domain';
import { environmentToken } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class LearningPackagePreviewClient {
	readonly #apiServer = inject(environmentToken).server;
	readonly #httpClient = inject(HttpClient);

	requestLearningPackagePreviewLink(
		entityId: string,
		entityType: LearningPackageEntityType
	) {
		return this.#httpClient.get<LearningPackagePreviewLinkResponse>(
			`${
				this.#apiServer
			}/api/scorm/${entityId}/preview?entityType=${entityType}`
		);
	}
}
